<script setup>
const props = defineProps({
  highlightedText: String,
  type: {
    type: String,
    default: "h1",
  },
  hasScrambleEffect: {
    type: Boolean,
    default: true,
  },
  hasColor: {
    type: Boolean,
    default: true,
  },
});
const highlighted = ref([]);
const { getCurrentTheme } = useTheme();

onMounted(() => {
  if (!props.hasColor) return;
  const spans = highlighted.value?.querySelectorAll(".highlighted");
  if (spans.length > 0) {
    spans.forEach((span) => {
      span.classList.add(`text-grey-1`);
    });
  }
});

const classes = constants.fontClasses;
const animators = ref([]);
onMounted(() => {
  let cumulativeWordIndex = 1;

  highlighted.value?.querySelectorAll(".highlighted").forEach((item, index) => {
    item.style.setProperty("--span-index", index + 1);
    animators.value.push(new TextAnimator(item));

    item.querySelectorAll(".word").forEach((word) => {
      word.style.setProperty("--word-index", cumulativeWordIndex);
      cumulativeWordIndex++;
    });
  });
});

const inView = () => {
  if (!props.hasScrambleEffect) return;
  if (animators.value.length < 1) return;
  animators.value.forEach((animator, index) => {
    animator.animate();
  });
};
</script>

<template>
  <div class="highlighted-text">
    <component-behavior-in-view :once="true" @in-view="inView" :threshold="0">
      <div
        :class="classes[type]"
        v-html="highlightedText"
        class="opacity-0"
      ></div>
      <div
        :class="classes[type]"
        v-html="highlightedText"
        class="highlighted-to-animate absolute left-0 top-0 h-full w-full"
        ref="highlighted"
      ></div>
    </component-behavior-in-view>
  </div>
</template>

<style scoped>
.observer {
  position: relative;
}
/* .highlighted-text:deep(.highlighted .word) {
  opacity: 0;
  transform: translateY(-0.5rem);
  transition: all 0.5s var(--cubic-ease);
  transition-delay: calc(var(--word-index) * 0.15s);
}
.observer.is-seen:deep(.highlighted .word) {
  opacity: 1;
  transform: translateY(0);
} */
</style>
